import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { StaticQuery, graphql } from 'gatsby';
import ReactMarkdown from "react-markdown"

import Calendly from '@components/calendly/book-a-call';
import ReapitWiget from '@components/reapit-widget/reapit'; 

/**
 * Components
 */
import Relative from '@components/relative/relative';
import BackgroundImage from 'gatsby-background-image-es5';

const AreyouReady = (props) => {
  return (
    <Relative background="#3E1052">
      <Container>
        <Row className="booking">
          <Col lg={4} className="booking__text align-self-center">
            <ReactMarkdown source={props.data.strapiGlobalConfig.Global_Are_You_Looking_Content} />
            <ReapitWiget class="footer-book-call btn btn-primary reapit-val-btn" label="Book Your Valuation"/>
          </Col>
          <Col className="is-static booking__image" lg={8}>
            <div className="image-holder">
              <BackgroundImage
                  Tag="section"
                  fluid={props.data.strapiGlobalConfig.Global_Are_You_Looking_Image.childImageSharp.fluid}
                  style={{width: '55%'}}
                  className="image__inner"
                  fadeIn={true}
                />
            </div>
          </Col>
        </Row>
      </Container>
    </Relative>
  )
}

export default function ReadytoLet() {
  return (
    <StaticQuery
      query={graphql`
      query {

        strapiGlobalConfig {
          Calendly_Link
          Global_Are_You_Looking_Content
          Global_Are_You_Looking_Image {
            childImageSharp {
              fluid(maxWidth: 800){
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

      }
    `}
      render={data => <AreyouReady data={data} />}
    />
  )
}