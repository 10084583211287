import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Carousel, Row, Col, Card } from 'react-bootstrap';
import { useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image/withIEPolyfill";
import classNames from 'classnames';
import { isMobile, isIOS } from "react-device-detect";


/**
 * Assets
 */
import './assets/styles/_index.scss';
import Fullscreen from '@static/svg/enlarge.svg';


const GallerySlider = (props, {className}) => {
  const slider = useRef();
  const [currentSlide, setCurrentSlide] = useState(1);
  const [fullscreen, setFullscreen] = useState(false);
  

  const openFullscreen = () => {
      setFullscreen(true);
    if (slider.current.requestFullscreen) {
      slider.current.requestFullscreen();
    } else if (slider.current.mozRequestFullScreen) {
      slider.current.mozRequestFullScreen();
    } else if (slider.current.webkitRequestFullscreen) {
      slider.current.webkitRequestFullscreen();
    } else if (slider.current.msRequestFullscreen) {
      slider.current.msRequestFullscreen();
    }
  }
  const closeFullscreen = () => {
      setFullscreen(false);
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  const toggleFullScreen = useCallback(() => {
    if(fullscreen) {
      closeFullscreen();
    } else {
      openFullscreen();
    }
  }, [fullscreen]);

  const handleEscape = useCallback((event) => {
    if((!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement)){
      setFullscreen(false);
    }
  }, [setFullscreen]);

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleEscape, false);
    document.addEventListener('mozfullscreenchange', handleEscape, false);
    document.addEventListener('MSFullscreenChange', handleEscape, false);
    document.addEventListener('webkitfullscreenchange', handleEscape, false);

    return () => {
      document.removeEventListener('fullscreenchange', handleEscape);
      document.removeEventListener('mozfullscreenchange', handleEscape);
      document.removeEventListener('MSFullscreenChange', handleEscape);
      document.removeEventListener('webkitfullscreenchange', handleEscape);
    };
  }, [handleEscape])

  return (
    <div className={classNames('slider-holder', className, {'is-fullscreen': fullscreen, 'is-iphone': isIOS && isMobile})} ref={slider}>

      <div className="slider-holder__inner">
        <Carousel
          onSlide={ind => setCurrentSlide(ind+1)}
          indicators={false}
        >
          {props.slides.map((Upload_Images, ind) => {
            const currentImage = Upload_Images.url
            return (
              <Carousel.Item key={ind}>
                <Card.Img variant="top" className="d-block w-100 gallery-slide" src={currentImage} alt="store" />
              </Carousel.Item>
            )
          })}
        </Carousel>
        <div className="slide-controls">
          <Row>
            <Col>
              <div className="slide-progress">
                {currentSlide} / {props.slides.length}
              </div>
            </Col>
            <Col md="auto">
              <div className="fullscreen" onClick={toggleFullScreen} role="presentation">
                <Fullscreen />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default GallerySlider
